/* LoginPage.css */
.login-background {
    /* Gradient Arka Plan */
    background: linear-gradient(to right, #2c3e50 0%, #4b6cb7 100%);
    
    /* Resimli Arka Plan için aşağıdaki satırı kullanın ve yukarıdaki satırı yorum yapın */
    /* background: url('background-image-url.jpg') no-repeat center center fixed; */
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
  }
  