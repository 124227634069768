.table th input {
  width: 100%;        /* İnputları genişlet */
  margin: 0.5rem 0;   /* Üst ve alttan boşluk ekle */
  padding: 0.25rem;   /* İnput içi boşluk */
}
.table th {
  background-color: #f8f9fa; 
  color: #333;              
  text-align: center;       
  border-bottom: 2px solid #dee2e6; 
}
.detay-paneli {
position: fixed;
top: 0;
right: -400px; /* Başlangıçta panel görünmez olacak */
width: 400px; /* Panelin genişliği */
height: 100%;
background-color: #f8f9fa;
box-shadow: -5px 0 15px rgba(0,0,0,0.2);
transition: right 0.3s ease-in-out;
z-index: 1000; /* Diğer içeriklerin üzerinde görünmesi için */
overflow-y: auto; /* İçerik fazlaysa kaydırmayı etkinleştir */
}

.detay-paneli.acik {
right: 0; /* Panel açık olduğunda */
}

.detay-paneli .card-header {
background-color: #007bff;
color: white;
padding: 10px 15px;
}

.detay-paneli .btn-close {
font-size: 20px;
color: white;
opacity: 0.8;
}

.detay-paneli .card-body {
padding: 15px;
line-height: 1.5;
font-size: 14px;
}

.detay-paneli .card-body p {
margin-bottom: 10px;
}

.detay-paneli .card-body h5 {
margin-top: 20px;
margin-bottom: 10px;
color: #007bff;
}

.detay-paneli .card-body ul {
padding-left: 20px;
}

.detay-paneli .card-body li {
list-style-type: disc;
}

.detay-paneli .card-body a {
color: #007bff;
text-decoration: none;
}

.detay-paneli .card-body a:hover {
text-decoration: underline;
}
.table th {
background-color: #2c3e50;
color: white;
}