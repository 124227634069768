.card-img-top {
  width: 150px; /* Örnek bir genişlik */
  height: auto; /* Yüksekliği otomatik olarak ayarla */
}
.label {
  font-weight: bold;
  color: #333; /* Varsayılan metin rengi */
  display: inline-block; /* Etiketi bir blok öğesi gibi görüntüler */
  margin-bottom: 0; /* Etiketin alt boşluğunu kaldır */
}

.card-text {
  margin-bottom: 0;
  padding: 0;
}

.card-body {
  padding: 20px;
}