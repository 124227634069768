.col-islemler {
    width: 20%; /* İşlemler sütunu için */
  }
  .col-id {
    width: 20%; /* Kullanıcı ID sütunu için */
  }
  .col-email {
    width: 60%; /* E-Posta sütunu için */
  }

