.active-link {
  border: 3px solid #d4a017 !important; /* Koyu sarı çerçeve */
  color: white !important;
}

.no-padding-left {
  padding-left: 0 !important;
}
/* .left-sidebar{
position: fixed;
overflow-y: auto;
height: 100%;
} */