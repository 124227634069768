/* HastaKayitRaporu.css */
.table-responsive {
  width: 100%;
  overflow-x: auto; /* Yatay kaydırma gerekiyorsa etkinleştir */
}

.table {
  width: 100%;
  max-width: 100%; /* Tablonun maksimum genişliği */
  table-layout: fixed; /* Tablo hücrelerinin genişliğini sabitler */
}
.table td.actions {
  min-width: 160px; /* İki ikon için yeterli minimum genişlik */
  text-align: center;
}

.table th, .table td {
  white-space: nowrap; /* Metnin yeni satıra geçmesini önler */
  overflow: hidden; /* Hücre içeriği kutunun dışına taşarsa gizler */
  text-overflow: ellipsis; /* İçerik taşarsa üç nokta (...) ekler */
  min-width: 120px; /* Minimum sütun genişliği */
}
.table th input {
    width: 100%;        /* İnputları genişlet */
    margin: 0.5rem 0;   /* Üst ve alttan boşluk ekle */
    padding: 0.25rem;   /* İnput içi boşluk */
  }
.table th {
    background-color: #f8f9fa; 
    color: #333;              
    text-align: center;       
    border-bottom: 2px solid #dee2e6; 
}
.detay-panel {
  position: fixed;
  top: 0;
  right: -400px; /* Başlangıçta panel görünmez olacak */
  width: 400px; /* Panelin genişliği */
  height: 100%;
  background-color: #f8f9fa;
  box-shadow: -5px 0 15px rgba(0,0,0,0.2);
  transition: right 0.3s ease-in-out;
  z-index: 1000; /* Diğer içeriklerin üzerinde görünmesi için */
  overflow-y: auto; /* İçerik fazlaysa kaydırmayı etkinleştir */
}

.detay-panel.acik {
  right: 0; /* Panel açık olduğunda */
}
.detay-panel .card-header .btn-close {
  color: white !important; /* Çarpı simgesinin rengini beyaz yap ve diğer stilleri geçersiz kıl */
  font-size: 20px;
  opacity: 1; /* Opaklığı ayarla */
}

.detay-panel .card-header {
  background-color: #2c3e50;
  color: white;
  padding: 10px 15px;
}


.detay-panel .btn-close {
  font-size: 20px;
  color: white;
  opacity: 0.8;
}

.detay-panel .card-body {
  padding: 15px;
  line-height: 1.5;
  font-size: 14px;
}

.detay-panel .card-body p {
  margin-bottom: 10px;
}

.detay-panel .card-body h5 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #007bff;
}

.detay-panel .card-body ul {
  padding-left: 20px;
}

.detay-panel .card-body li {
  list-style-type: disc;
}

.detay-panel .card-body a {
  color: #007bff;
  text-decoration: none;
}

.detay-panel .card-body a:hover {
  text-decoration: underline;
}
.btn-extra-sm {
  padding: 0.25rem 0.4rem; /* Daha az padding */
  font-size: 0.75rem; /* Daha küçük font boyutu */
}
/* Özel sütun genişlikleri */
.custom-sidebar {
  flex: 0 0 15%; /* Sidebar için %12.5 genişlik */
  max-width: 15%;
}

.custom-content {
  flex: 0 0 85%; /* İçerik için %87.5 genişlik */
  max-width: 85%;
}
.margin-top {
  margin-top: 8px; /* veya ihtiyacınıza göre ayarlayın */
}
.clickable-row {
  cursor: pointer;
}